import {defineStore} from "pinia";
import {apiRequest} from "@/repositories";
import cloneDeep from "lodash-es/cloneDeep";
import {AdminStoreStateProps} from "@/models";


export const useAdminStore = defineStore("admin", {
    state: (): AdminStoreStateProps => ({
        items: [],
        query: {
            page: 1,
            perPage: 50,
            name: ''
        },
        pagination: {
            count: 0,
            next_flag: false,
            page: 1,
            page_numbers: [1],
            per_page: 1,
            prev_flag: false
        },

        count: 0,
        listLoading: false,

        form: null,
        formOrigin: null,
        formLoading: false,
        formSaving: false,

        options: [],
        game_briefs: [],
        channel_briefs: [],
        connectedList: [],
        connectedOptions: [],

    }),
    getters: {},
    actions: {
        initForm() {
            this.form = {
                admin_id: 0,
                id: '',
                name: '',
                email: '',
                password: '',
                password_verified: '',
                access_flag: false,
                auth_type: 1,
            };
            this.formOrigin = cloneDeep(this.form)
        },
        resetForm() {
            this.form = cloneDeep(this.formOrigin);
        },
        async onList(): Promise<void> {
            this.listLoading = true
            return apiRequest.get({
                url: `/api/admin`,
                onError: false,
                params: this.query,
            })
                .then((data) => {
                    //  api data 에서 뭐가 떨어질지 모르겠음 , 일단은 값이 있는 res 사용
                    const {apiData} = data;


                    this.items = apiData.admin_items.map((ele: any) => {
                        return {
                            ...ele,
                            auth_type: ele.role_no,
                            num_connected_games: ele.num_games,
                        }
                    });


                    console.log("=>(admin.ts:68) this.items", this.items);

                    this.pagination = apiData.pagination;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.listLoading = false;
                })
        },

        async onCreate() {
            this.formSaving = true;
            return apiRequest.post({
                url: `/api/admin`,
                data: this.form
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {

                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onGet(adminId: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/admin/${adminId}`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.form = apiData.admin;
                    this.formOrigin = cloneDeep(apiData.admin);

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    this.form = null;
                    this.formOrigin = null;

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;
                })
        },

        async onUpdate(adminId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/admin/${adminId}`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onUpdateAccess(adminId: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/admin/${adminId}/access`,
                data: this.form,
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onUpdateAuthType(adminId: number, roleNo: number) {
            this.formSaving = true;
            return apiRequest.put({
                url: `/api/admin/${adminId}/role`,
                data: {role_no: roleNo},
            })
                .then((data) => {
                    this.formOrigin = cloneDeep(this.form);

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })
        },

        async onDelete(adminId: number) {
            this.formSaving = true;
            return apiRequest.delete({
                url: `/api/admin/${adminId}`,
            })
                .then((data) => {
                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                    this.formSaving = false;
                })

        },

        async onGetOptions(): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/admin/options`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.options = apiData.admin_options;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;
                })
        },

        async onGameConnected(adminId: number): Promise<void> {
            this.formLoading = true
            return apiRequest.get({
                url: `/api/admin/${adminId}/game-connected`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;

                    this.connectedList = apiData.game_briefs;


                    return Promise.resolve(apiData);
                })
                .catch((error) => {

                    return Promise.reject(error);
                })
                .finally(() => {
                    this.formLoading = false;
                })
        },

        async getConnectedChannelList(adminId: number): Promise<void> {
            return apiRequest.get({
                url: `/api/admin/${adminId}/channel-connected`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.connectedList = apiData.channel_briefs;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                })
        },
        async getConnectedChannelOptions(adminId: number): Promise<void> {
            return apiRequest.get({
                url: `/api/admin/${adminId}/channel/options/distinct`,
                onError: false,
            })
                .then((data) => {
                    const {apiData} = data;
                    this.connectedOptions = apiData.channel_admin_options;

                    return Promise.resolve(apiData);
                })
                .catch((error) => {
                    return Promise.reject(error);
                })
                .finally(() => {
                })
        },


        async onCreateConnectedAdminChannel( adminId: number , channelId: number) {
            return apiRequest.post({
                url: `/api/admin/${adminId}/channel/${channelId}`,
                onError: false,
            })
                .then((data) => {

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                })
        },
        async onDeleteConnectedAdminChannel(adminId: number, channelId: number ) {
            return apiRequest.delete({
                url: `/api/admin/${adminId}/channel/${channelId}`,
                onError: false,
            })
                .then((data) => {

                    return Promise.resolve(data.apiData);
                })
                .catch((error) => {
                    return Promise.reject(error.apiData);
                })
                .finally(() => {
                })
        },

    }

})
